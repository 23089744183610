<template>
	<div v-if="viewDebugElements">
		<slot />
	</div>
</template>

<script>
/**********************************************************************************************
**** semplice container per i debug ***********************************************************
***********************************************************************************************
*/
export default {
	name: 'DebugContainer',
	components: { },
	data: () => {
		return {
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] }
	},
	mounted() {
	},
	methods: {

	},
}
</script>

<style scoped lang="less">

</style>