<template>
	<standard-container title="jobs" description="" menuicon="jobs" nocard>
		<v-card>
			<v-card-text>
				<v-row :dense="compactVisualization">
					<v-col cols="12" md="6">
						<v-text-field ref="searchField" v-model="search" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" autofocus prepend-inner-icon="mdi-magnify" :label="gTranslate('Ricerca')" single-line hide-details clear-icon="mdi-close-circle" clearable />
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<v-row>
			<v-col cols="12">
				<v-expansion-panels :value="openedPanels" :multiple="search!==''">
					<template v-for="area in aree">
						<v-expansion-panel :key="area.Id" class="elevation-2" :class="area.Visibile ? 'd-block' : 'd-none'">
							<!-- :class="jobsPerTipo(area.CodiceArea).length>0 ? 'd-block' : 'd-none'" -->
							<v-expansion-panel-header class="headline warning--text">
								{{ area.DescrizioneArea }}
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div class="d-flex justify-end">
									<v-btn small color="warning" @click="editJob(null, area)">
										<v-icon left>
											mdi-plus
										</v-icon>{{ gTranslate('Nuovo job') }}
									</v-btn>
								</div>
								<v-list>
									<template v-for="job in jobsPerTipo(area.CodiceArea)">
										<v-list-item :key="job.Id" @click="editJob(job, area)">
											<v-list-item-content>
												<v-list-item-title>{{ job.DescrizioneJob }}</v-list-item-title>
											</v-list-item-content>
											<v-list-item-action>
												<v-btn small text :title="gTranslate('Modifica la competenze collegate a ') + job.DescrizioneJob" @click.stop="modificaCompetenzeCollegate(job, area)">
													<v-icon left>
														mdi-tools
													</v-icon> {{ gTranslate('competenze') }}
												</v-btn>
											</v-list-item-action>
										</v-list-item>
										<v-divider :key="job.Id + 'div'" />
									</template>
								</v-list>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</template>
				</v-expansion-panels>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12">
				<v-btn small icon :title="gTranslate('Ricarica la lista dal server')" :disabled="loading" :loading="loading" @click="refresh">
					<v-icon>mdi-refresh</v-icon>
				</v-btn>
			</v-col>
		</v-row>

		<debug-container>
			<v-row>
				<v-col cols="6">
					<debug-panel label="decodifiche.Aree">
						{{ aree }}
					</debug-panel>
				</v-col>
				<v-col cols="6">
					<debug-panel label="jobs">
						{{ jobs }}
					</debug-panel>
				</v-col>
			</v-row>
		</debug-container>
		<!-- inizio dialogJob -->
		<v-dialog v-model="dialogJob" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialogJob = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor" dark>
					<v-btn icon @click="dialogJob = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ currentJob.Id>0 ? gTranslate('Modifica job'): gTranslate('Nuovo job') }} - {{ currentArea.DescrizioneArea }}</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :disabled="!currentJob.DescrizioneJob || !currentJob.CodiceCategoriaLivello || saving" :loading="saving" @click="salvaJob">
							{{ gTranslate('Salva') }}
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row :dense="compactVisualization">
						<v-col cols="12" lg="6">
							<v-text-field ref="autofocus" v-model="currentJob.DescrizioneJob" required :rules="[ruleCampoObbligatorio]" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Nome')" />
						</v-col>
						<v-col cols="12" lg="6">
							<v-select v-model="currentJob.CodiceCategoriaLivello" required :rules="[ruleSelezionaValore]" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Categoria/Livello')" :items="decodifiche.CategoriaLivelloInquadramento" item-text="DescrizioneLivello" item-value="CodiceLivello" />
						</v-col>
						<v-col cols="12">
							<v-text-field v-model="currentJob.TitoloDiStudioRichiesto" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Titolo di studio richiesto')" />
						</v-col>
						<v-col cols="12">
							<v-textarea v-model="currentJob.ResponsabilitaAttivitaJob" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Responsabilità e attività attribuite')" rows="2" auto-grow />
						</v-col>
						<v-col cols="12">
							<v-textarea v-model="currentJob.AltreQualificazioni" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Altre qualificazioni')" rows="2" auto-grow />
						</v-col>
						<v-col cols="12">
							<v-textarea v-model="currentJob.Annotazioni" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="gTranslate('Annotazioni')" rows="2" auto-grow />
						</v-col>
					</v-row>

					<v-row v-if="error" dense>
						<v-col cols="12">
							<v-alert text type="error">
								<span class="title">{{ error }}</span><br><span class="caption">{{ errorDetail }}</span>
							</v-alert>
						</v-col>
					</v-row>

					<debug-container>
						<v-row :dense="compactVisualization">
							<v-col cols="12">
								<debug-panel label="currentJobCompetenze">
									{{ currentJobCompetenze }}
								</debug-panel>
							</v-col>
						</v-row>
						<v-row :dense="compactVisualization">
							<v-col cols="6">
								<debug-panel label="currentArea">
									{{ currentArea }}
								</debug-panel>
							</v-col>
							<v-col cols="6">
								<debug-panel label="currentJob">
									{{ currentJob }}
								</debug-panel>
							</v-col>
						</v-row>
					</debug-container>
				</v-container>
				<v-footer v-if="currentJobCompetenze">
					<v-row>
						<v-col cols="12">
							<span class="subtitle">Competenze</span>
						</v-col>
						
						<v-col cols="12">
							<v-simple-table dense>
								<template v-slot:default>
									<tbody>
										<tr v-for="item in currentJobCompetenze" :key="item.Id">
											<template v-if="visualizzaCompetenza(item.CodiceCompetenza)">
												<td>{{ decodificaNomeCompetenza(item.CodiceCompetenza) }}</td>
												<td class="text-right">
													<v-chip v-if="item.LivelloAtteso>0" x-small outlined="" color="grey" class="text-uppercase white--text mt-1 mb-1">
														{{ decodificaEtichettaLivelloCompetenza(item.LivelloAtteso) }}
													</v-chip>
												</td>
											</template>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
					</v-row>
				</v-footer>
			</v-card>
		</v-dialog>									
		<!-- fine dialogJob -->	
		<!-- inizio dialogJobLink -->
		<v-dialog v-model="jobLink.dialog" scrollable :fullscreen="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'md'" persistent transition="scale-transition" max-width="1200" @keydown.esc="jobLink.dialog = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor" dark>
					<v-btn icon @click="jobLink.dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ gTranslate('Competenze per') }} '{{ jobLink.descrizioneJob }}'</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :disabled="jobLink.saving" :loading="jobLink.saving" @click="updateJobLink">
							{{ gTranslate('Salva') }}
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card-actions>
					<v-text-field v-model="jobLink.search" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" autofocus label="Filtra competenze" class="ml-5 mr-5" prepend-inner-icon="mdi-magnify" single-line hide-details clear-icon="mdi-close-circle" clearable />
				</v-card-actions>				
				<v-card-text :style="($vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' || $vuetify.breakpoint.name === 'md') ? 'padding-left:10px;' : 'height: 600px;min-height: 600px;'">
					<v-simple-table dense class="tableJobLink">
						<template v-slot:default>
							<thead>
								<tr>
									<th style="width:10px" />
									<th>{{ gTranslate('Competenza') }}</th>
									<th class="d-none d-md-table-cell">
										{{ gTranslate('Tipo') }}
									</th>
									<th class="d-none d-md-table-cell">
										{{ gTranslate('Settore') }}
									</th>
									<th>{{ gTranslate('Livello atteso') }}</th>
									<th style="width:50px">
										{{ gTranslate('Peso') }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in jobLinkCompetenzeExt" :key="item.Id+'tdl'" :class="item.Job ? 'jobLinkSelected' : 'jobLinkUnselected'">
									<template v-if="visualizzaCompetenza(item.CodiceCompetenza)">
										<td><input v-model="item.Job" type="checkbox" style="display:block" @change="item.UiChanged = true"></td>
										<td><span>{{ item.NomeCompetenza }}</span></td>
										<td class="d-none d-md-table-cell">
											<span>{{ item.DescrizioneTipoCompetenza }}</span>
										</td>
										<td class="d-none d-md-table-cell">
											<span>{{ item.DescrizioneSettore }}</span>
										</td>
										<td>
											<div v-if="item.Job">
												<select v-model="item.LivelloCompetenzaAtteso" class="specialSelect" style="padding-left:5px; padding-right:15px; border-radius:2px; appearance: auto !important; background-color: white !important; color: black !important;" @change="item.UiChanged = true">
													<option v-for="(livello, index) in decodifiche.LivelliCompetenzePerBind" :key="index" :value="livello.Codice" :style="'background-color:' + livello.Color">
														{{ livello.Label }}
													</option>
												</select>
											</div>
										</td>
										<td>
											<div v-if="item.Job">
												<input v-model="item.Peso" class="specialInput" type="number" style="width:50px; padding-left:5px; border-radius:2px; appearance: auto !important; background-color: white !important; color: black !important;" @change="item.UiChanged = true">
											</div>
										</td>
									</template>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
				<v-container v-if="viewDebugElements" fluid>
					<v-row :dense="compactVisualization">
						<v-col cols="6">
							<debug-panel label="jobLink">
								{{ jobLink }}
							</debug-panel>
						</v-col>
						<v-col cols="6">
							<debug-panel label="jobLinkCompetenzeExt">
								{{ jobLinkCompetenzeExt }}
							</debug-panel>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialogJobLink -->					
	</standard-container>
</template>

<script>
import Vue from 'vue'
import StandardContainer from '@/components/standard-container'
import DebugPanel from '@/components/debug-panel'
import DebugContainer from '@/components/debug-container'
import lodash from 'lodash'
export default {
	name: 'JobsPage',
	components: { StandardContainer, DebugContainer, DebugPanel },
	data: () => ({
		search: '',
		dialogJob: false,
		saving: false,
		loading: false,
		error: '',
		errorDetail: '',
		currentArea: {},
		currentJob: {},
		currentJobCompetenze: [],
		// oggetto per gestione link job
		jobLink: {
			dialog: false,
			loading: false,
			saving: false,
			search: '',
			codiceJob: '',
			descrizioneJob: '',
			codiceArea: '',
			descrizioneArea: '',
			competenzeExt: []
		},
	}),
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		jobs() { return this.$store.getters['decodifiche'].Jobs },
		competenze() { return this.$store.getters['competenze'] },
		jobsCompetenze() { return this.$store.getters['decodifiche'].JobsCompetenze },
		// filtra (search) i dati nella modale jobLink
		jobLinkCompetenzeExt() {
			return this.jobLink.competenzeExt.filter(cp => { return !this.jobLink.search || ( cp.NomeCompetenza && cp.NomeCompetenza.toLowerCase().indexOf(this.jobLink.search.toLowerCase()) > -1 ) })
		},
		// genera una struttura specifica (array 0,1,2,...Npannelli-1) che serve per aprire tutti i pannelli quando si effettua una ricerca 
		openedPanels() {
			if(this.search) {
				var outList = []
				for(var ii=0; ii<this.decodifiche.Aree.length; ii++){
					outList.push(ii)
				}
				return outList
			}
			return []
		},
		aree() { 
			return lodash.orderBy(this.decodifiche.Aree, 'DescrizioneArea')
		},
	},
	mounted() {
		this.$refs.searchField.focus()
	},
	methods: {
		jobsPerTipo(codiceArea) {
			if(this.search) {
				return lodash.orderBy(lodash.filter(this.jobs, item => {
					var a = item.CodiceArea === codiceArea
					var c = item.DescrizioneJob && item.DescrizioneJob.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
					var d = item.CodiceJob && item.CodiceJob.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
					return a && (c || d)
				}), 'DescrizioneJob')
			} else {
				return lodash.orderBy(lodash.filter(this.jobs, {'CodiceArea': codiceArea}), 'DescrizioneJob')
			}
		},
		// apre la modale è imposta l'autofocus
		openDialog() {
			this.dialogJob = true
			setTimeout(() => {
				var afdummy = this.$refs.autofocus
				if (afdummy) afdummy.focus()
			}, 300)
		},
		// aggiungi / modifica job
		async editJob(job, area) {
			if(!job) {
				var negativeId = 0
				await this.$store.dispatch('getNegativeIncrementId').then(id => { negativeId = id }) // chiamata sincrona allo store !
				job = {
					Id: negativeId,
					CodiceJob: '',
					DescrizioneJob: '',
					CodiceArea: area.CodiceArea,
					CodiceCategoriaLivello: 0,
					ResponsabilitaAttivitaJob: '',
					TitoloDiStudioRichiesto: '',
					AltreQualificazioni: '',
					Annotazioni: '',
					Visibile: true,
				}
			}
			this.currentArea =  JSON.parse(JSON.stringify(area))
			this.currentJob = JSON.parse(JSON.stringify(job))
			// carica inoltre l'elenco delle competenze del job
			this.currentJobCompetenze = this.jobsCompetenze.filter(item => { return item.CodiceJob === this.currentJob.CodiceJob })
			this.openDialog()
		},
		decodificaEtichettaLivelloCompetenza(livello) {
			if(this.decodifiche && this.decodifiche.LivelliCompetenza) {
				return this.decodifiche.LivelliCompetenza['Livello'+livello]
			} 
			return null
		},
		decodificaColoreLivelloCompetenza(livello) {
			if(this.decodifiche && this.decodifiche.LivelliCompetenza) {
				return this.decodifiche.LivelliCompetenza['Colore'+livello]
			} 
			return null
		},		
		// estrae solo la descrizione delle competenza (con un check di esistenza che non si sa mai)
		decodificaNomeCompetenza(codiceCompetenza) {
			var dummy = this.competenze.find(e => e.CodiceCompetenza === codiceCompetenza)
			if(dummy) return dummy.NomeCompetenza
			return '-'
		},
		// in seguito alle #979 le competenze possono essere annullate/nascoste, sicuramente c'era un metodo più furbo per gestire sta cosa ma non l'ho trovato :-(
		visualizzaCompetenza(codiceCompetenza) {
			var dummy = this.competenze.find(e => e.CodiceCompetenza === codiceCompetenza)
			if(dummy) return dummy.Visibile
			return false
		},
		// salva job nuova / modificata
		salvaJob () {
			this.error = ''
			this.saving = true
			this.$store.dispatch('salvaJob', this.currentJob).then(() => {
				this.saving = false
				this.loading = false
				this.dialogJob = false
			}).catch(error => {
				console.error(error)
				this.error = 'Errore durante il salvataggio di un job'
				this.errorDetail = error
				this.loading = false
				this.saving = false
			}) 
		},
		// forza il ricaricamento della lista dal server
		refresh() {
			this.loading = true
			this.$store.dispatch('refreshJobs').then(()=>{
				this.loading = false
			})
		},
		// prepara l'oggetto per la gestione delle competenze collegate ad un job e poi apre la realtiva modale
		modificaCompetenzeCollegate(job, area) {
			this.jobLink.codiceJob = job.CodiceJob
			this.jobLink.descrizioneJob = job.DescrizioneJob
			this.jobLink.codiceArea = job.CodiceArea
			this.jobLink.descrizioneArea = area.DescrizioneArea
			this.jobLink.search = ''
			// carica le competenze (che ricordo contenere dei campi speciali per gestire il link) e le disaccoppia dallo store caricandole nella variabile jobLink.comptenzeExt (la quale poi verrà renderizzata passando attraverso la computed jobLinkCompetenzeExt che gestirà il filtro)
			Vue.set(this.jobLink, 'competenzeExt', JSON.parse(JSON.stringify(this.$store.getters['competenze'])))
			// carica nell'appena creata jobLink.comptenzeExt lo stato attuale dei link intersecando la jobsCompetenze
			this.jobLink.competenzeExt.forEach(cpItem => {
				cpItem.UiCodiceJob = job.CodiceJob // importante! va settatta l'apposita variabile per far sapaere all'api il codicejob indispensabile per il link
				var jcRow = this.jobsCompetenze.filter(jcItem => {
					return jcItem.CodiceJob === this.jobLink.codiceJob && jcItem.CodiceCompetenza === cpItem.CodiceCompetenza
				})
				if(jcRow.length>0) {
					cpItem.Job = true
					cpItem.LivelloCompetenzaAtteso = jcRow[0].LivelloAtteso
					cpItem.Peso = jcRow[0].Peso
				} else {
					cpItem.Job = false
					cpItem.LivelloCompetenzaAtteso = 0
					cpItem.Peso = 0
				}
			})
			// infine apre la modale
			this.jobLink.dialog = true
		},
		// estrae solo la descrizione delle competenza (con un check di esistenza che non si sa mai)
		decodificaNomeTipoCompetenza(codiceTipoCompetenza) {
			var dummy = this.decodifiche.TipoCompetenze.find(e => e.CodiceTipoCompetenza === codiceTipoCompetenza)
			if(dummy) return dummy.DescrizioneTipoCompetenza
			return '-'
		},
		updateJobLink() {
			this.jobLink.saving = true
			var cpExtChanged = this.jobLink.competenzeExt.filter(cp => {return cp.UiChanged})
			// console.warn('updateJobLink', cpExtChanged)
			this.$store.dispatch('updateJobLink', cpExtChanged).then(() => {
				this.jobLink.saving = false
				this.jobLink.dialog = false
			})
		},
	}

}
</script>

<style scoped lang="less">
.specialSelect{
	appearance: auto !important;
	background-color: white !important;
	color: black !important;
	-webkit-appearance: listbox !important;
    -moz-appearance: listbox !important;
}
.specialInput{
	appearance: auto !important;
	background-color: white !important;
	color: black !important;
	height: 18px !important;

}
/*
.jobLinkSelected {
	background-color: rgba(255,0,0,0.1);
}
*/
.jobLinkUnselected {
	td:not(:first-child) {
		span {
			opacity: 0.2 !important;
		}
	}
}

.tableJobLink{
	// width: 100%;
	td {
		// height: auto !important;
		padding-left: 3px;
		padding-right: 3px;
	}
}
</style>